import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-53a67161"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "address"
}
const _hoisted_2 = { class: "h2 address__title" }
const _hoisted_3 = { class: "h4 address__desc" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AddressInfo = _resolveComponent("AddressInfo")!

  return (!_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("almostThere")), 1),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("addressPage.reward")), 1),
        _createVNode(_component_AddressInfo, {
          info: _ctx.address,
          name: _ctx.contact?.fullName,
          onCorrect: _ctx.onCorrect,
          onEdit: _ctx.onEdit
        }, null, 8, ["info", "name", "onCorrect", "onEdit"])
      ]))
    : _createCommentVNode("", true)
}