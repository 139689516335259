
import { Address } from "@/models/Address"
import { Options, Vue } from "vue-class-component"

@Options({
  components: {},
  props: {
    info: {
      type: Object
    },
    name: {
      type: String
    }
  }
})
export default class AddressInfo extends Vue {
  info: Address | null
  name: string
}
