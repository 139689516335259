
import { Options, Vue } from "vue-class-component"
import AddressInfo from "@/components/Address/AddressInfo.vue"
import { Address } from "@/models/Address"
import { Contact } from "@/models/Contact"
import {
  getContactAddress,
  confirmContactAddress,
  getContact
} from "@/api/services/ContactService"
import { getInvite } from "@/api/services/InviteService"
import { Invite } from "@/models"

type QueryStatus = "completed" | "terminated" | "over_quota" | "error"
type QueryErrorCode = "100"

@Options({
  components: {
    AddressInfo
  }
})
export default class AddressCorrect extends Vue {
  address: Address | null = null
  contact?: Contact | null = null
  loading = false
  invite: Invite | null = null
  surveyStatus: boolean

  get status(): QueryStatus {
    return this.$route.query.status as QueryStatus
  }

  get errorCode(): QueryErrorCode {
    return this.$route.query.code as QueryErrorCode;
  }

  get id(): string {
    return this.$route.query.id as string
  }

  get type(): string {
    return this.$route.query.is_legacy as string
  }

  get typeIsEz(): boolean {
    return this.type === "true"
  }

  get isCompletedStatus(): boolean {
    return this.status === "completed"
  }

  async mounted(): Promise<void> {
    this.loading = true
    if (this.typeIsEz) this.onEdit()

    await this.getInvite()

    if (!this.status && this.invite?.status) {
      this.surveyStatus = this.invite?.status.toLowerCase() === "completed"
    }
    if (this.isCompletedStatus || this.surveyStatus) {
      await this.getContactInfo()

      if (!this.address?.hasAddress) {
        this.onEdit()
      }
    } else {
      this.checkNavigationByStatus()
    }
    this.loading = false
  }

  async getInvite() {
    const invite = await getInvite(this.id)

    if (invite) {
      this.invite = invite
    }
  }

  async getContactInfo() {
    await Promise.all([this.getContact(), this.getContactAddress()])
  }

  async getContact(): Promise<void> {
    this.contact = await getContact(this.id)
  }

  async getContactAddress(): Promise<void> {
    this.address = await getContactAddress(this.id)
  }

  checkNavigationByStatus(): void | boolean {
    switch (this.status) {
      case "terminated":
        this.$router.push({
          name: "terminatedV2",
          query: {
            id: this.id,
          },
        });
        break;
      case "over_quota":
        this.$router.push({
          name: "OverQuotaV2",
          query: {
            id: this.id,
          },
        });
        break;
      case "error":
        this.validateErrorCode()
        break;
      default:
    }
  }

  validateErrorCode() {
    switch (this.errorCode) {
      case "100":
        this.$router.push({
          name: "DecipherLoggedError"
        });
        break;
      default:
        this.$router.push({
          name: "SurveyExpiredV2",
          query: {
            id: this.id,
          },
        });
    }
  }

  async onCorrect() {
    this.$router.push({
      name: "allFinished",
      query: {
        id: this.id,
      },
    });
    if (this.address) {
      await confirmContactAddress(this.id)
    }
  }

  onEdit(): void {
    this.$router.push({
      name: "AddressEditV2",
      query: {
        id: this.id,
        is_legacy: this.type
      }
    })
  }
}
