import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3b62bae4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "address-info" }
const _hoisted_2 = { class: "address-info__content h4" }
const _hoisted_3 = { class: "capitalize" }
const _hoisted_4 = { class: "capitalize address-info__pre" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.name), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.info?.hasAddress ? _ctx.info?.toString : "No address found"), 1)
    ]),
    _createElementVNode("p", null, [
      _createElementVNode("a", {
        href: "#",
        class: _normalizeClass(["primary-rewards-btn address-info__button", { 'is-disabled': !_ctx.info?.hasAddress }]),
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('correct')), ["prevent"]))
      }, " Yes, the address is correct ", 2)
    ]),
    _createElementVNode("p", null, [
      _createElementVNode("a", {
        href: "#",
        class: "completed__link violet-link",
        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit('edit')), ["prevent"]))
      }, "No, I need to edit my address.")
    ])
  ]))
}